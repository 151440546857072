import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../components/instructores.module.css"

export default ({ data }) => {
  const portada = data.portada
  const portadaM = data.portadaM

  return (
    <Layout portadaDesk={portada} portadaMob={portadaM} title="Instructores">
      <SEO title="Instructores" />
      <main>
        <div className={styles.mainContainer}>
          <div className={[styles.imgBorder, styles.yoyi].join(" ")}>
            <div className={styles.imgContainer}>
              <Img fluid={data.yoyi.childImageSharp.fluid} />
            </div>
          </div>
          <div className={[styles.textContainer, styles.yoyi].join(" ")}>
            <h2>Yoyi Fretes</h2>
            <h4>
              Lic. En Nutrición, instructora y propietaria de Allinea Pilates.
            </h4>
            <p>
              Siempre me gustó la idea de ayudar a las personas. Me recibí de
              Nutricionista en el año 2011, ese mismo año tuve mi primer
              encuentro con el Método Pilates y desde ese momento sentí que esto
              era más que ejercicios. En el 2016 tome la decisión de abrir mi
              propio estudio, pero sabía que había mucho más por aprender en el
              camino. En el 2018 conocí el Método Clásico y me apasioné. Hoy
              Aprendiz de Romana’s Pilates (2020); encontré la certificación que
              buscaba para poder seguir ayudando con Pilates a las personas.
            </p>
          </div>
          <div className={[styles.imgBorder, styles.nahir].join(" ")}>
            <div className={styles.imgContainer}>
              <Img fluid={data.nahir.childImageSharp.fluid} />
            </div>
          </div>
          <div className={[styles.textContainer, styles.nahir].join(" ")}>
            <h2>Nahir González</h2>
            <h4>Lic. en Fisioterapia y Kinesiología, instructora</h4>
            <p>
              A lo largo de la carrera, lo que más me llamaba la atención era la
              parte postural y kinésica y en el penúltimo año de la carrera
              (2015), durante las pasantías, comencé a sentir dolores fuertes en
              la zona lumbar, por lo cual me hicieron varios estudios. El
              resultado: hernias. El dolor iba en aumento y empecé con la
              medicina tradicional, pero no era de mucha ayuda ya que el dolor
              seguía siendo muy intenso. Entonces empecé a investigar sobre
              actividades que me ayudaran a mejorar esta dolencia y así llegué
              al Pilates (2017). En dos meses de clases mi dolor cesó bastante.
              Me gustó mucho la conciencia corporal, la activación consciente y
              el ver cómo mi cuerpo fue mejorando en lo postural con la
              práctica. Todo esto se relacionaba bastante con mi carrera y con
              mis ganas de ayudar a mejorar la calidad de vida de las personas a
              través de movimientos conscientes. Me encantó tanto que en 2017
              empecé una formación, y luego otra en 2018. En ese mismo año
              conocí a una persona con mis mismas ganas de aprender el verdadero
              Método y me llevó a conocer el Pilates Clásico que de verdad
              cumplió con todas mis expectativas profesionalmente. Actualmente
              estoy en un proceso de transición al Pilates Clásico que cambio
              totalmente mis perspectivas.
            </p>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query InstructoresQuery {
    portadaM: file(relativePath: { eq: "instructoras-p-m.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portada: file(relativePath: { eq: "instructores-p.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yoyi: file(relativePath: { eq: "yoyi.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nahir: file(relativePath: { eq: "nahir.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
